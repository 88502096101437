body {
  margin: 0px;
  padding: 0px;
}
ul li {
  list-style: none;
}

.sas {
  font-family: "Yatra One", cursive;
  word-spacing: 3px;
}

.Facilities1 ul li {
  font-size: 23px;
  list-style: square;
}

/* ///about us css start/// */
/* .sam{
  background: url(../src/Images/bgimg.jpg) no-repeat center;
  background-size: cover;
  height: 500px;
}

#inner-containersss{
  width: 100%;
  height: 500px;
  
 
} */
.sams {
  margin-top: 160px;
}

/* /////home css start/// */
.btn-outline2 {
  color: white !important;
}
.himg1 img {
  height: 700px !important;
  width: 100%;
}
.himg2 img {
  height: 700px !important;
  width: 100%;
}
.himg3 img {
  height: 700px !important;
  width: 100%;
}
.himg4 img {
  height: 700px !important;
  width: 100%;
}

@media (max-width: 600px) {
  .himg1 img {
    width: 100%;
    height: 244px !important;
  }
  .himg2 img {
    width: 100%;
    height: 244px !important;
  }
  .himg3 img {
    width: 100%;
    height: 244px !important;
  }
  .himg4 img {
    width: 100%;
    height: 244px !important;
  }
}

@media (min-width: 1200px) {
  .mgn12 {
    margin-left: 20px !important;
  }
}

/* /////home css end/// */

a:hover,
a:visited,
a:link,
a:active {
  text-decoration: none !important;
  color: black;
}

.abouteus h1 {
  text-align: center;
  font-size: 50px;
}
.abouteus1 h1 {
  text-align: center;
  color: rgb(119, 115, 115);
}
.abouteus2 {
  text-align: center;
  font-size: 22px;
  margin-top: 20px;
  color: rgb(80, 78, 78);
}
.abouteus3,
.abouteus4 {
  margin-top: 20px;
  color: rgb(29, 28, 28) !important;
}
.aboutbgimg {
  background-image: url(../src/Images/footerimg.jpeg) no-repeat center;
  height: auto;
  width: auto;
  margin-bottom: 40px;
}
.aboutbgimg1 img {
  height: 550px !important;
  width: 100%;
}
.about_img_contant {
  font-size: 40px;
  margin-top: 20px;
}
.about_img_contant1 {
  font-size: 20px;
}

.aboutimg img {
  width: 100%;
  margin-top: 90px;
  box-shadow: 2px 2px 28px 0px rgb(0 0 0 / 75%);
}

@media only screen and (min-width: 0px) and (max-width: 480px) {
}
@media only screen and (min-width: 480px) and (max-width: 768px) {
}

/* ///about us css end/// */
/* /////Courses css start/// */
.Coursesimg img {
  background-size: cover;
  width: 100%;
  height: 600px;
  display: block;
}
.Coursesss h1 {
  margin-top: 10px;
  text-align: center;
  font-size: 50px;
}
.Courses-content h2 {
  color: rgb(119, 115, 115);
  margin-top: 10px;
  margin-bottom: 15px !important;
}
.Courses-content h1 {
  margin-top: 10px;
}

@media (max-width: 600px) {
  .Coursesimg img {
    background-size: cover;
    width: 100%;
    height: 200px;
    display: block;
  }
}

/* ///Courses us css end/// */
/* ///Activities us css start/// */

.Activities_content h2 {
  text-align: center;
  color: rgb(119, 115, 115);
}

/* ///Activities us css end/// */

/* ///contact us css start/// */
.addreshcss {
  margin-top: 150px;
}

@media (max-width: 600px) {
  .addreshcss {
    margin-top: 0px;
  }
}

.contact_text h1 {
  margin-top: 10px;
  font-size: 50px;
  border-bottom: 2px solid rgb(56, 14, 241);
}
.our_branch {
  margin-top: 25px;
  background-color: #f2f2f2;
}
.our_branch h1 {
  font-size: 45px;
}
.our_branch a {
  margin-top: 10px;
  display: flex;
  font-size: 23px;
  padding-top: 10px;
  padding-bottom: 10px;
}
.home1 {
  font-size: 34px !important;
}
.our_branch a {
  margin-bottom: 8px;
}
.our_branch > a:hover {
  border-left: 5px solid #4a0ec0;
  border-radius: 4px;
  color: #b11e6f !important;
  background-color: #fff !important;
}

.contact_form {
  background-color: #f2f2f2;
}
.contact_form h1 {
  color: #000;
  font-size: 43px;
  padding: 5px;
  border-bottom: 3px solid #f2f2f2;
}
.contact_form h3 {
  font-size: 25px;
}

.text1 input {
  width: 100%;
  height: 50px;
  border-radius: 5px;
  border: none;
  border-color: #f2f2f2;
}

.text1 textarea {
  width: 100%;
  height: 130px;
  border-radius: 5px;
  border: none;
  border-color: #f2f2f2;
}

.btn-outline1,
.btn-outline1:hover,
.btn-outline1:focus {
  background: linear-gradient(
    90deg,
    rgba(177, 30, 111, 1) 0%,
    rgba(74, 14, 192, 1) 100%
  ) !important;
  border: 2px solid #fff !important;
  margin-top: 1rem;
  float: right;
  font-size: 21px !important;
  padding: 10px 20px !important;
  border-radius: 10px !important;
  color: #fff !important;
  text-align: center;
}

.btn-outline12 {
  background: linear-gradient(
    90deg,
    rgba(177, 30, 111, 1) 0%,
    rgba(74, 14, 192, 1) 100%
  ) !important;
  border: 2px solid #fff !important;
  margin-top: 1rem;
  float: right;
  font-size: 18px !important;
  padding: 8px 16px !important;
  border-radius: 15px !important;
  color: #fff !important;
  text-align: center;
}

/* ///contact us css end/// */
/* ///Header us css start/// */
.header_s {
  background: linear-gradient(90deg, #35d342 0%, #4337eb 100%);
  height: auto;
  color: white;
  display: flex;
}

.callicons {
  color: white;
  font-size: 45px !important;
}
.icons1 {
  display: flex !important;
  float: right;
}

.gmail {
  float: right;
  /* text-align: center; */
}

@media (max-width: 450px) {
  .gmail {
    /* float: right; */
    text-align: center;
  }
}
@media (max-width: 600px) {
  .gmail {
    /* float: right; */
    text-align: center;
  }
}
@media (max-width: 900px) {
  .gmail {
    float: right;
  }
}
@media (max-width: 1100px) {
}

/* ///Header us css end/// */

.socileicon {
  float: right;
  display: flex;
}
.youtube {
  color: rgb(243, 28, 28);
  font-size: 45px !important;
  float: right;
}
.fb {
  color: rgb(47, 43, 250);
  font-size: 45px !important;
  float: right;
}

.insta {
  color: white;
  font-size: 45px !important;
  float: right;
}

.twit {
  color: blue;
  font-size: 45px !important;
  float: right;
}
.navbar_main {
  background-color: #f9f8f6 !important;
  float: right;
}

.numbers {
  float: right;
}

.ulli > li a {
  margin-left: 30px;
  list-style: none;
  font-size: 17px;
  cursor: pointer;

  text-align: center;
  position: relative;
  z-index: 9;
  display: block;
  line-height: 50px;
  padding-top: 0px;
  padding-bottom: 0px;
  outline: medium none;
  text-align: justify;
  text-decoration: none;
}

.dropdown1 {
  font-size: 16px !important;
  margin: 0px !important;
}
.ulli > li a :hover {
  color: #b11e6f;
}
.ulli {
  display: flex;
}
.header_down {
  display: flex;
}
.bgimgs {
  background: url(../src/Images/bgimg.jpg) no-repeat center;
  background-size: cover;
  height: 550px;
  width: auto;
}
.sam12 {
  background: url(../src/Images/footerimg.jpeg) no-repeat center;
  height: auto;
  width: auto;
  margin-bottom: 40px;
}
/* .carouseldone{
  background-size: cover;
  height: 800px;
  width: auto;
} */

.footer__container {
  /* background-image: url(../src/Images/footerimg.jpeg); */
  background-color: rgb(51, 50, 50);
  color: white;
  display: flex;
  flex-direction: column;
  width: 100% !important;
  float: unset;
}
ul > b {
  font-size: 28px;
}
.header_img img {
  width: 211px;
  height: 168px;
}
.footer_li li {
  font-size: 19px;
  margin-top: 10px;
  font-weight: 480;
  display: list-item;
}

.marqueecss > h2 {
  color: #b11e6f;
}
.fineArt_css {
  font-size: 36px;
  color: #000;
}
.samss {
  background: linear-gradient(
    90deg,
    rgb(153, 219, 139) 0%,
    rgb(44, 92, 60) 100%
  ) !important;
}
.btn {
  display: inline-block;
  vertical-align: middle;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  cursor: pointer;
  background-image: none;
  border: 1px solid transparent;
  padding: 6px 12px;
  font-size: 14px;
  line-height: 1.42857143;
  border-radius: 4px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.btn-outline,
.btn-outline:hover,
.btn-outline:focus {
  background: linear-gradient(
    90deg,
    rgba(177, 30, 111, 1) 0%,
    rgba(74, 14, 192, 1) 100%
  ) !important;
  border: 2px solid #fff !important;
  margin-top: 1rem;
  font-size: 21px !important;
  padding: 10px 20px !important;
  border-radius: 30px !important;
  color: #fff !important;
  text-align: center;
}
.Our_Courses {
  background: url(../src/Images/artboard.png) no-repeat center;
  background-size: cover;
  height: 500px;
  width: 100%;
}

.pac-col4:hover {
  transition: 0.1s;
  transform: translateY(-6px);
  box-shadow: 0 25px 60px 0 rgb(0 0 0 / 20%);
}
.footer_li1,
.fb1,
.instaa,
.twit1,
.youtube1 {
  /* text-align: center !important; 
  */
  float: right;
}

.imgs1,
.imgs2,
.imgs3,
.imgs4 {
  border-radius: 5px;
}
.imgs5,
.imgs6 {
  border-radius: 20px;
  height: 320px;
  width: 100%;
}

.imgs12 {
  font-size: 45px !important;
}
.instaa {
  color: rgb(245, 91, 150);
  font-size: 65px !important;
}
.youtube1 {
  color: rgb(243, 28, 28);
  font-size: 65px !important;
}
.fb1 {
  color: rgb(47, 43, 250);
  font-size: 65px !important;
}
.twit1 {
  color: blue;
  font-size: 65px !important;
}
/* .gmail1{
  margin-left: 30px;
} */
.maaimg {
  width: 100%;
}

@media (max-width: 1100px) {
  .header_img img {
    width: 211px;
    height: 168px;
  }
}
@media (max-width: 600px) {
  .navbar_main {
    background-color: darkblue;
    margin-left: -34px;
    float: none;
  }
  .footer_li1,
  .fb1,
  .instaa,
  .twit1,
  .youtube1 {
    text-align: center !important;
    padding-bottom: 25px !important;
  }

  .gmail {
    float: right;
    /* text-align: center; */
    /* display: list-item!important; */
  }
  /* .gmail1{
    margin-left: 5px!important;
  } */
  /* .mgn{
    margin-left: 10px;
    margin-right: 10px;
    margin-top: 5px;
  } */

  /* .mgn1{
    margin-left: 10px;
    margin-right: 10px;
    /* margin-top: 5px; */
  /* } */
  .numbers {
    font-size: 12px;
    margin: 0px;
  }
  .youtube {
    color: rgb(243, 28, 28);
    font-size: 25px !important;
    float: right;
    margin: 10px;
  }
  .socileicon {
    float: right;
  }
  .fb {
    color: rgb(47, 43, 250);
    font-size: 25px !important;
    float: right;
    margin: 10px;
  }
  .insta {
    color: white;
    font-size: 25px !important;
    float: right;
    margin: 10px;
  }
  .twit {
    color: blue;
    font-size: 25px !important;
    float: right;
    margin: 10px;
  }
  .header_img img {
    width: 324px;
    height: 146px;
  }
  .ulli {
    display: list-item;
  }
  .header_down {
    display: list-item;
  }
}

@media (max-width: 400px) {
  .header_img img {
    width: 267px;
    height: 165px;
    margin-left: 4px;
  }
}

@media (max-width: 800px) {
  .header_img img {
    width: 267px;
    height: 165px;
    margin-left: 4px;
  }
}

@media (max-width: 900px) {
  .header_img img {
    width: 267px;
    height: 165px;
    margin-left: 45px !important;
  }
}

@media (max-width: 1100px) {
  .header_img img {
    width: 267px;
    height: 165px;
    margin-left: 20px;
  }
  .ulli {
    display: list-item;
  }
}

@media screen and (min-device-width: 481px) and (max-device-width: 768px) {
  .youtube {
    color: rgb(243, 28, 28);
    font-size: 25px !important;
    float: right;
  }
  .socileicon {
    float: right;
  }
  .fb {
    color: rgb(47, 43, 250);
    font-size: 25px !important;
    float: right;
  }

  .insta {
    color: white;
    font-size: 25px !important;
    float: right;
  }

  .twit {
    color: blue;
    font-size: 25px !important;
    float: right;
  }
}

/* .App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */
.page-container {
  position: relative;
  min-height: 100vh;
}
.footerAbout {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 2.5rem; /* Footer height */
}

@media (min-width: 768px) {
  .navbar-expand-md {
    flex-flow: row nowrap;
    justify-content: flex-start;
    float: right;
  }
}
/* 
@media screen and (min-device-width: 768pxpx) and (max-device-width: 1100px){
  .navbar-expand-md {
      flex-flow: row nowrap;
      justify-content: flex-start; 
      
      display: flex !important;
  }
  } */

.dropdown11 {
  position: relative;
  display: inline-block;
  z-index: 9999 !important;
}
.dropdown-content1 p,
.dropdown-content12 p,
.dropdown-content13 p {
  font-size: 16px;
  color: rgb(255, 255, 255) !important;
}

.dropdown-content1 {
  display: none;
  position: absolute;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 9999 !important;
  background-color: rgb(56, 55, 55);
  color: white !important;
}

.dropdown11:hover .dropdown-content1 {
  height: 180px;
  display: block;
  z-index: 9999 !important;
  width: 100%;
  margin-top: -218px;
}
/* .dropdown11:hover .display_none{ 
   display:none !important;
   background-color: rebeccapurple;
   
} */
.dropdown-content12 {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  padding: 12px 16px;
  z-index: 9999 !important;
}
.dropdown11:hover .dropdown-content12 {
  height: auto;
  display: block;
  z-index: 9999 !important;
  width: 100%;
  margin-top: -218px;

  /* background: linear-gradient(90deg, #7ceb8b 0%, #b3a4ee 100%); */
  background-color: rgb(56, 55, 55);
  color: white !important;
}
.dropdown-content13 {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  padding: 12px 16px;
  z-index: 9999 !important;
}
.dropdown11:hover .dropdown-content13 {
  height: auto;
  display: block;
  z-index: 9999 !important;
  width: 100%;
  margin-top: -218px;
  /* background: linear-gradient(90deg, #7ceb8b 0%, #b3a4ee 100%); */
  background-color: rgb(56, 55, 55);
  color: white !important;
}

@media screen and (min-device-width: 768px) and (max-device-width: 984px) {
  .art_name_btn a {
    font-size: 13px !important;
  }
  .dropdown11 > span {
    font-size: 13px !important;
  }
  .dropdown12 > span {
    font-size: 13px !important;
  }
  .dropdown13 > span {
    font-size: 13px !important;
  }
}

@media screen and (min-device-width: 1204px) and (max-device-width: 1340px) {
  /* .art_name_btn a {
    font-size: 15px !important;
  }
  .dropdown11 > span {
    font-size: 15px !important;
  }
  .dropdown12 > span {
    font-size: 15px !important;
  }
  .dropdown13 > span {
    font-size: 15px !important;
  } */
}

/* @media screen and (min-device-width: 1008px) and (max-device-width: 1204px) {
  .dropdown11:hover .dropdown-content1 {
    margin-top: -245px;
    display: block;
    z-index: 9999 !important;
    width: 360px;
    margin-left: -138px;
    text-align: center;
    padding-left: -180px !important; 
    height: 200px;
  }

  .dropdown11:hover .dropdown-content12 {
    margin-top: -245px;
    display: block;
    z-index: 9999 !important;
    width: 364px;
    margin-left: -175px; 
    height: 200px;
  }
  .dropdown11:hover .dropdown-content13 {
    margin-top: -245px;
    display: block;
    z-index: 9999 !important;
    width: 364px;
    margin-left: -180px; 
    height: 210px;
  }
}

@media screen and (min-device-width: 320px) and (max-device-width: 768px) {
  .dropdown11:hover .dropdown-content1 {
    margin-top: -245px !important;
    display: block;
    z-index: 9999 !important;
    width: 360px;
    margin-left: -138px;
    text-align: center;
    padding-left: -180px !important; 
    height: 210px;
  }

  .dropdown11:hover .dropdown-content12 {
    margin-top: -245px;
    display: block;
    z-index: 9999 !important;
     width: 360px;
    margin-left: -175px; 
    height: 200px;
  }
  .dropdown11:hover .dropdown-content13 {
    margin-top: -245px;
    display: block;
    z-index: 9999 !important;
    width: 360px;
    margin-left: -180px; 
    height: 210px;
  }
} */
